<template>
  <div class="warp">
    <iframe v-if="path" :src="path" width="100%" height="100%"></iframe>
    <div class="tips" v-else>请前往PC端查看详情</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "", // 当前路由路径 
    };
  },
  computed: {},
  mounted() {
    this.path = this.$route.query.path; 
  },
  methods: {
  }
};
</script>
<style lang="less" scope>
.warp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .tips {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
